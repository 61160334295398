<template>
  <div class="default-wrapper wrapper">
    <div class="thanks-holder" v-if="order">
      <div class="title-heading text-center">
        <img src="/images/icons/thanks-check.svg" alt="">
        <h1>{{$static('thanks.title', 'Дякуємо за замовлення!')}} </h1>
        <!--        <h1>Дякуємо за замовлення! Оплата пройшла успішно</h1>-->
      </div>
      <div class="order-num text-center">
        {{$static('thanks.order-num', 'Замовлення:')}} <span>#{{ order.id }}</span>
      </div>
      <div class="thanks-notification text-center" v-if="pay.name === 'custom'">
        <p v-html="payText"></p>
      </div>
      <div class="thanks-order-items">
        <div class="panel-bordered">
          <div class="cart-item" v-for="(item, index) in items" :key="index">
            <div class="item-holder">
              <div class="item-info">
                <div class="image bg-cover" :style="{'background-image': $filters.getItemImage(item.preview)}"></div>
                <div class="right-side">
                  <div class="product-name">{{ $dynamic(item, item.title, 'title') }}</div>
                  <div class="product-count">
                    {{ $static('busked.count', 'Кількість:') }}
                    <span>{{ item.count }}{{ $static('busked.nam', 'шт') }}</span>
                  </div>
                </div>
              </div>

              <div class="additional">
                <div class="additional-line" v-if="item.price">
                  <div class="add-name">{{ $static('busked.price', 'Ціна') }}</div>
                  <p class="value">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</p>
                </div>
                <template v-if="item.additionSelect">
                  <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                    <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                    <p class="value" v-if="m.price">{{ $filters.money(m.price) }} {{ $store.getters.getSettings.symbol }}
                    </p>
                  </div>
                </template>

                <template v-if="item.additionUSelect">
                  <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                    <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                    <p class="value" v-if="m.price">{{ $filters.money(m.price) }} {{ $store.getters.getSettings.symbol }}
                    </p>
                  </div>
                </template>

                <div class="item-total">{{ item.count }} x {{ $filters.money(itemPrice(item)) }}
                  {{ $store.getters.getSettings.symbol }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customer-info">
        <div class="panel-bordered">
          <div class="order-related-info">
            <h3>Інформація про замовника</h3>
            <ul>
              <li v-if="delivery">
                <div class="name">Доставка:</div>
                <div class="value">{{ $dynamic(delivery.manifest, delivery.manifest.title, 'title') }}</div>
              </li>
              <li v-if="pay">
                <div class="name">Оплата:</div>
                <div class="value">{{ $dynamic(pay.manifest, pay.manifest.title, 'title') }}</div>
              </li>
            </ul>
          </div>
          <div class="billing-info">
            <h3>Всього:</h3>
            <ul>
              <li>
                <div class="name">{{ items.length }} товара на суму:</div>
                <div class="value">{{ order.total }} ₴</div>
              </li>
              <!--              <li>-->
              <!--                <div class="name">Вартість доставки:</div>-->
              <!--                <div class="value">безкоштовно</div>-->
              <!--              </li>-->
              <li class="total">
                <div class="name">До сплати:</div>
                <div class="value">
                  <div class="price-element">{{ order.total }} ₴</div>
                </div>
              </li>
            </ul>
            <div class="status-label success" v-if="order.pay_status === 'pay'">
              <span>Оплачено</span>
            </div>
            <div class="status-label proccess" v-else-if="order.pay_status === 'cpay'">
              <span>Відміна</span>
            </div>
            <div class="status-label proccess" v-else>
              <span>Очікує оплати</span>
            </div>

          </div>
        </div>
      </div>
      <div class="button-holder">
        <router-link :to="{name: 'CabinetHistory'}" class="default-btn btn-no-icon">{{
            $static(
                'cabinet.title2',
                'Історія замовлень'
            )
          }}
        </router-link>
        <!--        <a href="#" class="default-btn btn-no-icon"></a>-->
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Thanks',
  props: ['id'],
  data() {
    return {
      order: undefined
    }
  },
  computed: {
    payments() {
      return this.$store.getters.getPayment
    },
    pay() {
      return this.order?.info?.pay_type ? this.payments.find(x => x.id === this.order.info.pay_type) : undefined
    },
    delivery() {
      return this.order?.info?.delivery ? this.order.info.delivery : undefined
    },
    items() {
      return this.order?.info?.busked ? this.order.info.busked : []
    },
    payText() {
      return this.pay?.manifest?.text ? this.getPayText(this.pay?.manifest?.text) : undefined
    }
  },
  mounted() {
    this.getOrder(this.id)
  },
  methods: {
    getPayText(text) {
      text = text.replaceAll('\n', '<br>')
      text = text.replaceAll('{{id}}', this.order.id)
      return text
    },
    addition_price(item) {
      return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addition_Uprice(item) {
      return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    itemPrice(item) {
      return (item.price * item.count) + this.addition_price(item) + this.addition_Uprice(item)
    },
    getOrder(id) {
      fetch('/public/order/' + id).then(response => response.json()).then(data => {
        if (data.error) {
          return
        }
        this.order = data
        console.log(data)
      })
    }
  }
}
</script>
