<template>
  <div class="personal-order-list-item" v-if="order">
    <!--    <a href="javascript:void(0)" @click="showDeliveryMap" data-toggle="modal" class="show-in-map" v-if="order?.info?.delivery">-->
    <!--      <span class="text">Показати на карті</span>-->
    <!--      <img src="/images/icons/map-loc.svg" alt="">-->
    <!--    </a>-->
    <div class="list-item-top" data-toggle="collapse" :data-target="'#order'+order.id" aria-expanded="false"
         :aria-controls="'#order'+order.id">
      <div class="date_status order_success">
        <div class="date_status-body">
          <span class="date">
            <span class="order-number">{{ $static('cabinet.order-id', 'Замовлення') }} #{{
                order.id
              }}</span> | {{ new Date(order.created_at).toLocaleDateString() }}
          </span>
          <div class="status-bar">
            <span class="status">{{ statusList[status] }} | {{ getPayStatus }}</span>
          </div>
        </div>
        <div class="price-element">{{ order.total }} грн</div>
      </div>
      <div class="price-box">
        <div class="price-element">{{ order.total }} грн</div>
      </div>
      <div class="product-previews">
        <div class="preview-count" v-if="plusBusked > 0">+{{ plusBusked }}</div>
        <div class="product-preview bg-cover image" v-for="(item, index) in slicedBusked" :key="index"
             :style="{'background-image': $filters.getItemImage(item.preview)}"></div>
      </div>
      <div class="icons-holder">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 7.5L10 12.5L15 7.5" stroke="#212121"/>
        </svg>
      </div>
    </div>
    <!-- full information about order-->
    <div class="collapse" :id="'order'+order.id">
      <div class="personal-order-list-item-body">
        <div class="personal-order-list-item-holder">
          <!--          <div class="delivery-laps panel-bordered">-->
          <!--            <ul>-->
          <!--              <li v-for="(progress, index) in progressList" :key="index"-->
          <!--                  :class="{'done': index<realStatus, 'in-proccess': index===realStatus, 'future': index>realStatus}">-->
          <!--                <span>{{progress.label}}</span>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--            <div class="mobile-laps-trigger desktop-hidden" >-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="personal-order-product-wrapper">
            <!-- order product item-->
            <div class="cart-item" v-for="(item, index) in busked" :key="index">
              <div class="item-holder">
                <div class="item-info">
                  <div class="image bg-cover" :style="{'background-image': $filters.getItemImage(item.preview)}"></div>
                  <div class="right-side">
                    <a href="#" class="product-name">{{ item.title }}</a>
                    <div class="additional" v-if="item.u_select?.length">
                      <div class="additional-line" v-for="(o, s_index) in item.u_select" :key="s_index">
                        <div class="add-name">{{ o.item.name }}:</div>
                        <div class="value" v-if="o.item.price">{{ o.item.price }} грн</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="option-box">
                  <div class="item">
                    <div class="item-name">{{ $static('cabinet.order-price', 'Ціна') }}:</div>
                    <div class="value">{{ item.price }} грн</div>
                  </div>
                  <div class="item text-right">
                    <div class="item-name">{{ $static('cabinet.order-total', 'Сума') }}:</div>
                    <div class="value">{{ item.count }} x {{ itemTotal(item) }} грн</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end order product item-->
            <!-- order list item actions-->
            <div class="personal-order-list-item-bottom">
              <div class="lines">
                <div class="line promocode">
                  <div class="name">{{ $static('cabinet.promocode', 'Промокод') }}:</div>
                  <div class="value" v-if="order.info?.promo">{{ order.info.promo }}</div>
                  <div class="value" v-else>----</div>
                </div>
                <div class="line bonus">
                  <div class="name">{{ $static('cabinet.accrued-bonuses', 'Бонуси за замовлення') }}:</div>
                  <div class="value" v-if="order.info.bonus">{{ bonus }}</div>
                  <div class="value" v-else>0</div>
                </div>
                <div class="bonus-btns d-flex align-items-center justify-content-between">
                  <button class="default-btn btn-outline" v-if="!bonus_ok" @click="getMyBonus">{{ $static('cabinet.get-bonuses', 'Отримати бонуси') }}</button>
                  <p class="bonus-status" v-else-if="bonus > 0 ">{{ $static('cabinet.bonuses-get-already', 'Бонуси вже отримані') }}</p>
                </div>

                <a :href="'#modal_bonus'+order.id" data-toggle="modal" class="default-link"></a>
              </div>
            </div>
            <!--end  order list item actions-->
          </div>
        </div>
        <div class="personal-order-list-item-footer ">
          <div class="panel-bordered footer-holder">
            <div class="left-item-footer">
              <div class="order-related-info">
                <ul>
                  <li v-if="order.delivery?.manifest">
                    <div class="name">{{ $static('cabinet.del-type', 'Тип доставки') }}:</div>
                    <div class="value">{{ order.delivery.manifest.name }}</div>
                  </li>
                  <li v-if="pay_type?.manifest">
                    <div class="name">{{ $static('cabinet.payment-type', 'Тип оплати') }}:</div>
                    <div class="value">{{ pay_type.manifest.title }}</div>
                  </li>
                  <li v-if="delivery_type?.manifest?.city">
                    <div class="name">{{ $static('cabinet.city', 'Місто') }}</div>
                    <div class="value">{{ order.info.delivery.city }}</div>
                  </li>
                  <li v-if="delivery_type?.manifest?.warehouse">
                    <div class="name">{{ $static('cabinet.warehouse', 'Відділення') }}</div>
                    <div class="value">{{ order.info.delivery.warehouse }}</div>
                  </li>
                  <li v-if="delivery_type?.manifest?.address">
                    <div class="name">{{ $static('cabinet.address', 'Адреса') }}</div>
                    <div class="value">{{ order.info.delivery.address }}</div>
                  </li>
                  <li v-if="delivery_type?.manifest?.zip">
                    <div class="name">{{ $static('cabinet.zip', 'ZIP') }}</div>
                    <div class="value">{{ order.info.delivery.zip }}</div>
                  </li>

                  <li v-if="delivery_type?.manifest?.floor">
                    <div class="name">{{ $static('cabinet.floor', 'Поверх') }}</div>
                    <div class="value">{{ order.info.delivery.floor }}</div>
                  </li>
                  <li v-if="delivery_type?.manifest?.apartment">
                    <div class="name">№ {{ $static('cabinet.apartment', 'квартири') }}:</div>
                    <div class="value">{{ order.info.delivery.apartment }}</div>
                  </li>
                </ul>
              </div>
              <!--              <div class="checkbox-group">-->
              <!--                <div class="default-checkbox">-->
              <!--                  <input type="checkbox" id="no_call" name="no_call" :checked="noCall">-->
              <!--                  <label for="no_call" @click.stop.prevent="">{{$static('cabinet.dont-call', 'Не дзвонити у двері')}}</label>-->
              <!--                </div>-->
              <!--                <div class="default-checkbox">-->
              <!--                  <input type="checkbox" id="leave_door" name="leave_door" :checked="leave">-->
              <!--                  <label for="leave_door" @click.stop.prevent="">{{$static('cabinet.leave-door', 'Залишити біля дверей')}}</label>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="right-item-footer">
              <div class="billing-info">
                <ul>
                  <li>
                    <div class="name">{{ $static('cabinet.total-busked', 'Замовлення') }}</div>
                    <div class="value">{{ totalBusked }} грн</div>
                  </li>
                  <li v-if="order.info.promo_discount">
                    <div class="name">{{ $static('cabinet.promo-discount', 'Знижка по промокоду') }}</div>
                    <div class="value">{{ order.info.promo_discount }} грн</div>
                  </li>
                  <li v-if="order.info.bonus_discount">
                    <div class="name">{{ $static('cabinet.bonus-discount', 'Використані бонусів') }}</div>
                    <div class="value">{{ order.info.bonus_discount }} грн</div>
                  </li>
                  <li v-if="order.info.delivery_price">
                    <div class="name">{{ $static('cabinet.del-price', 'Ціна доставки') }}</div>
                    <div class="value">{{ order.info.delivery_price }} грн</div>
                  </li>
                  <li class="total">
                    <div class="name">{{ $static('cabinet.total-total', 'ВСЬОГО') }}:</div>
                    <div class="value">
                      <div class="price-element">{{ order.total }} грн</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="comment-curier-area" v-if="order?.info?.comment">
          <div class="label-custom">{{ $static('cabinet.curier-comment', 'Коментар') }}</div>
          <div class="text">{{ order.info.comment }}</div>
        </div>
      </div>
    </div>
    <!-- end full information about order-->
  </div>

  <Teleport to="body">
    <div class="modal custom-modal text-center modal-bonus fade" :id="'modal_bonus'+order.id" tabindex="-1"
         role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" v-if="bonusStatus">
          <div class="modal-header" style="background-color: #fff !important;"></div>
          <div class="modal-body" style="text-align: center">
            <div class="getted-bonus">
              <h2>{{ $static('cabinet.bonus_confirm', 'Бонуси нараховано!') }} </h2>
              <div class="coin">
                <img src="/images/coin.png" alt="Бонуси">
                <div class="bonus-panel" v-if="account">
                  <span>Ваші бонуси: <span class="num">{{ MyBonus }}</span></span>
                </div>
              </div>
              <div class="button-holder">
                <button type="button" class="default-btn btn-outline" data-dismiss="modal" aria-label="Close">
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-else>
          <div class="modal-header" style="background-color: #fff !important;"></div>
          <div class="modal-body">
            <div class="delivery-laps" style="text-align: center">
              <div class="coin"><img src="/images/coin.png" alt="Бонуси"></div>
              <p>{{$static('cabinet.bonus_text', 'Щоб нарахувати бонуси вам потрібно спочатку сплатит за товар')}} </p>
              <div class="button-holder">
                <button type="button" class="default-btn btn-outline" data-dismiss="modal" aria-label="Close">
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'HistoryItemRow',
  components: {},
  props: ['order'],
  data() {
    return {
      statusList: {},
      payStatusList: {},
      minimumStatus: {},
      progressList: {},
      bonusStatus: false
    }
  },
  computed: {

    account() {
      return this.$store.getters.getAccount
    },
    MyBonus() {
      return this.account?.bonus ? this.account.bonus : 0
    },
    bonus() {
      return this.order.info.bonus
    },
    bonus_ok() {
      return this.bonusStatus || this.order.info.bonus_ok || (this.order.info.bonus === 0)
    },
    payment() {
      return this.$store.getters.getPayment
    },
    delivery() {
      return this.$store.getters.getDelivery
    },
    surname() {
      return this.order?.info?.surname ? this.order.info.surname : ''
    },
    phone() {
      return this.order?.info?.phone ? this.order.info.phone : ''
    },
    busked() {
      return this.order?.info?.busked ? this.order.info.busked : []
    },
    totalBusked() {
      return this.busked?.reduce((prev, next) => this.itemTotal(next) + prev, 0)
    },
    phoneValid() {
      return this.phone.startsWith('38') ? '+' + this.phone : this.phone
    },
    plusBusked() {
      return this.busked.length - 2
    },
    slicedBusked() {
      return this.busked.slice(0, 2)
    },
    pay_type() {
      return this.order?.info?.pay_type ? this.payment.find(pay => pay.id === this.order.info.pay_type) : undefined
    },
    delivery_type() {
      return this.order?.delivery
    },
    status() {
      return this.order?.status
    },
    pay_status() {
      return this.order?.pay_status
    },
    getPayStatus(){
      return this.payStatusList[this.pay_status] ? this.payStatusList[this.pay_status] : this.pay_status
    }
  },
  mounted() {
    this.progressList = [
      {label: this.$static('progress-list.p1', 'Нове замовлення')},
      {label: this.$static('progress-list.p2', 'Прийнято')},
      {label: this.$static('progress-list.p3', 'На кухні')},
      {label: this.$static('progress-list.p4', 'Зібрано')},
      {label: this.$static('progress-list.p5', 'Передано на пакування')},
      {label: this.$static('progress-list.p6', 'пакуванні')},
      {label: this.$static('progress-list.p7', 'Запаковано')},
      {label: this.$static('progress-list.p8', 'Доставляється')},
      {label: this.$static('progress-list.p9', 'Завершено')}
    ];

    let statuses = [
      {label: this.$static('slist.new', 'Новий'), value: 'new'},
      {label: this.$static('slist.process', 'Підтверджено'), value: 'process', minProgress: 2},
      {label: this.$static('slist.progress', 'Відправлено'), value: 'progress', minProgress: 7},
      {label: this.$static('slist.delivery', 'Отримано'), value: 'delivery', minProgress: 8},
      {label: this.$static('slist.back', 'Повернення'), value: 'back', minProgress: 9},
      {label: this.$static('slist.cancel', 'Відміна'), value: 'cancel', minProgress: 9}
    ];
    statuses.forEach(status => {
      this.statusList[status.value] = status.label;
      this.payStatusList[status.value] = status.label;
      if (status.minProgress) {
        this.minimumStatus[status.value] = status.minProgress;
      }
    });
    let payStatuses = [
      {label: this.$static('pstatus.npay', 'Очікує сплати'), value: 'npay'},
      {label: this.$static('pstatus.pay', 'Сплачено'), value: 'pay'},
      {label: this.$static('pstatus.cpay', 'Відміна'), value: 'cpay'}
    ];
    payStatuses.forEach(status => {
      this.payStatusList[status.value] = status.label;
    })
  },
  methods: {

    getMyBonus(id) {
      fetch('/public/get_bonus', {method: 'POST', body: JSON.stringify({id: this.order.id})})
          .then(response => response.json())
          .then(json => {
            this.bonusStatus = json.status
            if (json.bonus) {
              this.$store.commit('setBonus', json.bonus)
            }
            let showBonus = document.querySelector(`a[href="#modal_bonus${this.order.id}"]`)
            if (showBonus) {
              showBonus.click()
            }
          })
    },
    showDeliveryMap() {
      window.showDeliveryMapModal(this.order.info.delivery)
    },
    itemTotal(item) {
      return (item.price * item.count)  + this.addition_price(item) + this.addPrice(item)
    },
    addition_price(item) {
      return item?.add && item.add.length ? item.add.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addPrice(item) {
      return item.u_select && item.u_select.length ? item.u_select.reduce((prev, next) => next.item?.price ? next.item?.price : 0 + prev, 0) : 0
    },
    getOrderStatus(order) {
      switch (order.status) {
        case 'cancel':
          return 'order_canceled'
        case 'back':
          return 'order_canceled'
        case 'progress':
          return 'order_send'
        default:
          return 'order_success'
      }
    },
    reorder(order) {
      let busked = order.info.busked;
      this.$store.commit('setBusked', busked.map(i => {
        i.additionSelect = []
        i.additionUSelect = []
        return i
      }));
      this.$router.push({name: 'Order'})
    },
    getPayName(order) {
      if (order?.info?.pay_type) {
        return this.payments.find(i => i.id === order.info.pay_type)
      }
      return {}
    },
    buskedPreview(order) {
      return order.info.busked.slice(0, 2)
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    }
  }
}
</script>