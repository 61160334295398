<template class="busked">
  <div class="bottom-nav">
    <ul>
      <li :class="{'disable': !hasHistory}">
        <a href="javascript:void(0)" @click="goBack">
          <img src="/images/icons/back.svg">
        </a>
      </li>
      <li>
        <router-link :to="{name: 'home'}" :active-class="'active'" class="home-button">
          <img src="/images/icons/Home.svg">
        </router-link>
      </li>
      <li class="quick-card-sticker-li">
        <div id="quick_card_panel_sticker">
          <div class="quick-card-sticker-holder">
            <a href="javascript:void(0);">
                <span class="notification">
                  {{ busked.length }}
                </span>
              <img src="/images/icons/bag-outline-menu.svg">
            </a>
            <div class="value">{{ $filters.money(total) }} {{ $store.getters.getSettings.symbol }}</div>
          </div>
        </div>
      </li>
      <li>
        <a href="javascript:void(0);" class="show-menu-btn">
            <span class="show-menu-btn-holder">
              <span></span>
              <span></span>
              <span></span>
            </span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" class="login-trigger">
          <img src="/images/icons/user-filled.svg">
        </a>
      </li>
    </ul>
  </div>

  <div class="frame-modal-wrapper quick-cart" :class="{'opened': isBuskedOpen}" id="quick_cart">
    <div class="modal-wrapper-holder">
      <div class="frame-modal-header">
        <div class="name h2">{{ $static('busked.title', 'Ваша корзина') }}</div>
        <a href="javascript:void(0);" class="close-cart" @click="toggleBusked">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>
      <div class="frame-modal-body" v-if="busked.length">
        <ul class="frame-modal-list">
          <li class="cart-item" v-for="(item, index) in busked" :key="index">
            <div class="item-holder">
              <a class="remove">
                <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(order, index)">
              </a>
              <div class="item-info">
                <div class="image bg-cover" :style="{'background-image': $filters.getItemImage(item.preview)}"></div>
                <div class="right-side">
                  <router-link :to="{ name: 'Item', params: { category: categoryURL(item), id: item.id }, query: $route.query }">
                    {{ item.title }}
                  </router-link>


                  <!--                  <div class="name">{{item.title}}</div>-->
                  <div class="info-labels">
                    <span class="info-label">{{ $static('busked.count', 'Кількість:') }} <span>{{ item.count }}
                        {{ $static('busked.nam', 'шт') }}</span></span>
                  </div>
                  <div class="product-price">
                    <div class="additional-line" v-if="item.price">
                      <div class="add-name">{{ $static('busked.price', 'Ціна') }}</div>
                      <p class="value">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</p>
                    </div>
                  </div>
                  <div class="additional" v-if="item.additionSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                      <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                      <p class="value" v-if="m.price">{{ $filters.money(m.price) }} {{ $store.getters.getSettings.symbol }}
                      </p>
                    </div>
                  </div>
                  <div class="additional" v-if="item.additionUSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                      <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                      <p class="value" v-if="m.price">{{ $filters.money(m.price) }} {{ $store.getters.getSettings.symbol }}
                      </p>
                    </div>
                  </div>
                  <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }}
                    {{ $store.getters.getSettings.symbol }}
                  </div>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
      <div class="frame-back-link">
        <a href="javascript:void(0)" class="back-link">
          <img src="/images/icons/left.svg">
          <span class="text">{{ $static('busked.continue', 'Продовжити покупки') }}</span>
        </a>
      </div>
      <div class="frame-modal-bottom">
        <div class="total">
          <span class="total-name">{{ $static('busked.total', 'Всього:') }}</span>
          <span class="total-value">{{ total }} <small>{{ $store.getters.getSettings.symbol }}</small></span>
        </div>
        <button class="default-btn trigger-to-order" @click="orderPage">
          <span class="btn-text">{{ $static('busked.order', 'Оформити') }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="thx" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title', 'Дякуємо за покупку!') }}</div>
        <p>{{ $static('thanks.subtitle', 'Переходимо до оплати...') }}</p>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="error" style="background: rgba(0,0,0,0.5);" @click="closeModal">
    <div class="thanks-body" >
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title', 'ALERT!') }}</div>
        <p>{{ $static('thanks.subtitle', 'Сталася помилка :(') }}</p>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="errorModal" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.error', 'Сталася помилка') }}</div>
        <p>{{ $static('thanks.subtitle_error', 'Будь ласка перевірте ваші данні і спробуйте ще.') }}</p>
      </div>
    </div>
  </div>

  <div class="frame-modal-wrapper-bg" :class="{'is-visible': isBuskedOpen}" @click="toggleBusked"></div>
  <div class="quick-card-sticker" id="quick_card_sticker" @click="toggleBusked">
    <div class="quick-card-sticker-holder">
      <a href="javascript:void(0);">
        <span class="notification">
          {{ busked.length }}
        </span>
        <img src="/images/icons/bag.svg">
      </a>
    </div>
  </div>
</template>

<script>
import { fixBodyPadding } from '@/utils/fixBodyPadding'

export default {
  name: 'Busked',
  data(){
    return{
    }
  },
  mounted() {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.enableClosingConfirmation();
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(this.goBack);
    }
    (function ($) {
      'use strict';

      // $('.quick_cart_trigger, #quick_card_sticker a').on('click', function () {
      //   fixBodyPadding();
      //   $('body').addClass('no-scroll');
      //   $('#quick_cart').addClass('opened');
      //   $('.frame-modal-wrapper-bg').addClass('is-visible');
      //   return false;
      // });

      // $('.frame-modal-wrapper-bg').on('click', function () {
      //   fixBodyPadding();
      //   $(this).removeClass('is-visible');
      //   $('.quick-card-sticker-li').removeClass('active');
      //   $('.overlay-menu').removeClass('active');
      //   $('.show-menu-btn').removeClass('open');
      //   $('body').removeClass('no-scroll');
      //
      //   $('#quick_cart').removeClass('opened');
      //   return false;
      // });

      $('.frame-modal-wrapper .close-cart').on('click', function () {
        fixBodyPadding();
        $('.frame-modal-wrapper').removeClass('opened');
        $('.quick-card-sticker-li').removeClass('active');
        $('body').removeClass('no-scroll');
        $('.frame-modal-wrapper-bg').removeClass('is-visible');
        return false;
      });

      $('.frame-back-link .back-link').on('click', function () {
        $('.frame-modal-wrapper').removeClass('opened');
        $('body').removeClass('no-scroll');
        fixBodyPadding();
        $('.frame-modal-wrapper-bg').removeClass('is-visible');
        return false;
      });

      $(function () {
        if ($(window).scrollTop() >= 100) {
          $('#quick_card_sticker,.fixed-filter-trigger').addClass('stickytop');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 100) {
            $('#quick_card_sticker,.fixed-filter-trigger').addClass('stickytop');
          } else {
            $('#quick_card_sticker,.fixed-filter-trigger').removeClass('stickytop');
          }
        });

      });
    })(window.$);
  },
  beforeUnmount() {
    (function ($) {
      'use strict';
      $('.frame-modal-wrapper').removeClass('opened');
      $('body').removeClass('no-scroll');
      fixBodyPadding();
      $('.frame-modal-wrapper-bg').removeClass('is-visible');

    })(window.$);
  },
  methods : {
    toggleBusked(){
       this.$store.commit('setBuskedOpen', !this.isBuskedOpen)
    },
    closeModal(event) {
      let target = event.target;
      while (target){
        if (target.classList.contains('is-visible')){
          target.classList.remove('is-visible')
          return
        }
        target = target.parentElement
      }
    },
    hasHistory() {
      return window.history.length > 2
    },
    goBack() {
      if (window.history.state.back) {
        this.$router.back()
      } else {
        window.Telegram.WebApp.close()
      }
    },
    orderPage() {
      this.$router.push({name: 'Order'});
    },
    addition_price(item) {
      return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addition_Uprice(item) {
      return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    itemPrice(item) {
      return (item.price * item.count) + this.addition_price(item) + this.addition_Uprice(item)
    },
    delBasked(item, index) {
      this.$store.commit('delBasked', index)
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    }
  },
  computed: {
    isBuskedOpen(){
      return this.$store.getters.getBuskedOpen
    },
    busked() {
      return this.$store.getters.getBusked
    },
    total() {
      return this.$store.getters.getBuskedTotal
    }
  }
}
</script>

<style scoped>
li > a.active {
  background-color : #212121;
  width            : 100%;
}

li a.home-button.active img {
  filter : invert(100)
}
</style>