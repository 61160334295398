import { reactive, computed, triggerRef, watchEffect, ref } from 'vue'

class I18N {
  constructor({local = {}, lang}) {
    this.local  = ref(local);
    this.lang   = ref(lang);
    this.update = reactive({count: 0})
  }
  
  install(app, options) {
    app.config.globalProperties.$static  = (key, basic) => {
      if (this.local.value[key]) {
        return this.local.value[key];
      }
      this.local.value[key] = basic;
      return this.local.value[key];
    };
    app.config.globalProperties.$dynamic = (item, basic, key) => {
      // console.log(item, basic, key, this.lang);
      if (item && item.translate && item.translate[this.lang] && key && item.translate[this.lang][key]) {
        return item.translate[this.lang][key]
      }
      return basic;
    };
    
    app.config.globalProperties.$load = (local, lang) => {
      this.local.value = Object.assign(this.local.value, local);
      this.lang        = lang.toLowerCase();
      this.update.count++
    };
    
    app.config.globalProperties.$local = () => {
      return this.local
    };
    
    app.config.globalProperties.$lang       = () => {
      return this.lang
    };
    
    app.config.globalProperties.$updateLang = () => {
      return this.update.count
    };
    
    app.config.globalProperties.$dynamics = () => {
      return this.dynamic
    };
  }
}

export default I18N
