import CabinetDelivery from '@/components/CabinetDelivery';
import CabinetHistory from '@/components/CabinetHistory';
import CabinetInfo from '@/components/CabinetInfo';
import Shop from '@/components/Shop';
import AuthRequire from '@/views/AuthRequire';
import Cabinet from '@/views/Cabinet';
import Pages from '@/views/Pages';
import Subscribe from '@/views/Subscribe';
import Subscribe_B from '@/views/Subscribe_B';
import Thanks from '@/views/Thanks';
import { createRouter, createWebHistory } from 'vue-router'
import Catalog from '../views/Catalog';
import Home from '../views/Home';
import NotFound from '../views/NotFound';
// import Home from '../views/Home.vue'
import Order from '../views/Order';
import Item from '../views/Item';
import Reviews from '../views/Reviews';
import Page from '../components/Page';

const routes = [
  {
    path     : '/',
    component: Shop,
    redirect : {name: 'Home'},
    children : [
      {
        path     : '/',
        name     : 'home',
        component: Home
      },
      {
        path     : '/about',
        name     : 'about',
        component: Page,
        props    : {pagename: 'about'}
      },
      {
        path     : '/policy',
        name     : 'policy',
        component: Page,
        props    : {pagename: 'policy'}
      },
      {
        path     : '/oferta',
        name     : 'offerta',
        component: Page,
        props    : {pagename: 'oferta'}
      },
      {
        path     : '/dostup',
        name     : 'dostup',
        component: Page,
        props    : {pagename: 'dostup'}
      },
      {
        path     : '/delivery',
        name     : 'delivery',
        component: Page,
        props    : {pagename: 'delivery'}
      },
      {
        path     : '/catalog/:category',
        name     : 'catalog',
        props    : true,
        component: Catalog
      },
      {
        path     : '/catalog/:category/:id',
        name     : 'Item',
        props    : true,
        component: Item
      },
      {
        path     : '/reviews',
        name     : 'reviews',
        props    : true,
        component: Reviews
      },
      {
        path     : '/thanks/:id',
        name     : 'thanks',
        props    : true,
        component: Thanks
      },
      
      {
        path     : '/cabinet',
        name     : 'Cabinet',
        component: Cabinet,
        redirect : {name: 'CabinetInfo'},
        children : [
          {
            path     : '',
            name     : 'CabinetInfo',
            component: CabinetInfo,
            alias    : ['/info', '']
          },
          {
            path     : 'auth',
            name     : 'AuthRequire',
            component: AuthRequire
          },
          {
            path     : 'delivery',
            name     : 'CabinetDelivery',
            component: CabinetDelivery
          },
          {
            path     : 'history',
            name     : 'CabinetHistory',
            component: CabinetHistory
          }
        
        ]
      },
      {
        path     : '/order',
        name     : 'Order',
        props    : true,
        component: Order
      },
      {
        path     : '/pages',
        name     : 'Pages',
        props    : true,
        component: Pages
      },
      {
        path     : '/:pathMatch(.*)*',
        name     : 'NotFound',
        component: NotFound
      }
    ]
  },
  {
    path     : '/shop/:id?',
    name     : 'subscribe',
    props    : true,
    component: Subscribe
  },
  {
    path     : '/shopb/:id?',
    name     : 'subscribeb',
    props    : true,
    component: Subscribe_B
  },
  
];

const router = createRouter({
                              history       : createWebHistory(process.env.BASE_URL),
                              routes,
                              scrollBehavior: (to, from, savedPosition) => {
                                if (savedPosition) {
                                  return savedPosition;
                                } else if (to.hash) {
                                  return {
                                    selector: to.hash
                                  };
                                } else {
                                  return {x: 0, y: 0};
                                }
                              }
                            });

// let last_path = localStorage.getItem('last_path');

function initRecord() {
  router.beforeEach((to) => {
    if (window.change) return false;
    // console.log(to);
    (function ($) {
      'use strict';
      $('.personal-sidebar-bg').removeClass('is-visible');
      $('.personal_area-sidebar').removeClass('opened');
      
      $('.frame-modal-wrapper-bg').removeClass('is-visible');
      $('.overlay-menu').removeClass('active');
      $('.show-menu-btn').removeClass('open');
      $('body').removeClass('no-scroll');
      
      $('#quick_cart').removeClass('opened');
      $('.open-nav').removeClass('is-visible');
      
    })(window.$);
    window.scrollTo(0, 0);
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute(
        'content',
        `${window.location.protocol}//${window.location.host}${to.path}`
      );
    localStorage.setItem('last_path', to.path);
    return true;
    // return false
  });
  
  // let lastTs = 0;
  // window.addEventListener('scroll', (event) => {
  //   if (lastTs + 100 < event.timeStamp) {
  //
  //     lastTs = event.timeStamp;
  //     localStorage.setItem('save_y', window.pageYOffset);
  //   }
  // });
}

router.isReady().then(() => {
  // if (last_path && window.location.pathname !== last_path) {
  //   router.push(last_path);
  // }
  // try {
  // let lastSaveY = parseInt(localStorage.getItem('save_y'), 10);
  // setTimeout(() => {window.scrollTo(0, lastSaveY)}, 100)
  // } catch (e) {
  //   console.log(e)
  // }
  initRecord();
});

export default router;
