<template>
  <div class="default-wrapper wrapper">
  <div class="not-found">
    <div class="num">404</div>
    <p>{{$static('404.text', 'Сторінку не знайдено')}}</p>
    <router-link :to="{name: 'home'}" class="default-btn" style="color:#ffffff;text-decoration: none">
      <span class="btn-text">{{$static('404.back', 'Назад на головну')}}</span>

    </router-link>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'Not Found',
    data() {
    }
  }
</script>
