<template>
  <div class="extended-modal" :class="{'is-opened':isOpen}" @click="checkClose" v-html="html">

  </div>
</template>


<script>
export default {
  name: 'OnStartModal',
  data() {
    return {
      isOpen : false,
      content: '',
      timer  : undefined
    }
  },
  computed: {
    body: {
      get() {
        return this.$store.getters.getPages ? this.$store.getters.getPages['modal_onstart'] : {}
      },
      set(value) {}
    },
    html() {
      return this.isOpen && this.body.template ? this.body.template : ''
    },
    settings() {
      return this.body?.settings ? this.body.settings : undefined
    }
  },
  methods : {
    close() {
      this.isOpen = false
      if (this.settings.oneTime) {
        localStorage.setItem(`modal_${this.body.id}`, new Date().getTime() / 1000 | 0)
      }else{
        localStorage.removeItem(`modal_${this.body.id}`)
      }
    },
    open() {
      if (this.settings.oneTime && localStorage.getItem(`modal_${this.body.id}`)) {
        return
      }
      if (!this.body || !this.body.template){
        return;
      }
      this.isOpen = true
    },
    checkClose(event) {
      let list = [event.srcElement.classList, event.srcElement.parentElement.classList];
      if (list.some(i => i.contains('make-close'))) {
        this.close()
        return
      }
      if (list.some(i => i.contains('make-exit'))) {
        if (window.Telegram?.WebApp?.close) {
          window.Telegram.WebApp.close();
        }
        window.close();
        return;
      }
    }
  },
  watch   : {
    settings(value) {
      if (!value) {
        return
      }
      if (value.delay !== undefined && value.delay > 0 && !this.timer) {
        this.timer = setTimeout(this.open, value.delay * 1000)
      } else {
        this.open()
      }
    }
  }
}
</script>

<style lang="less">
.extended-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 11111;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;

  .back-blur {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.is-opened {
    z-index: 1111111111111;
    opacity: 1;
    visibility: visible;


  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1111;

    img {
      width: 30px;
    }
  }
}

.info-modal {


  h2 {
    margin-top: 0;
  }

  .modal-picture {

    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .img-mob {
      display: none;
    }

    .img-desc, .img-mob {
      background-size: cover;
      background-position: right bottom;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }


  .button-holder {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .default-btn {
      margin: 0px 15px;
    }
  }

  &.age-modal {
    text-align: center;


    .content-modal {
      padding: 50px 100px;
    }

    .logo {
      margin-bottom: 25px;

      img {
        display: block;
        margin: auto;
        max-height: 35px;
      }
    }
  }

  &.consultant-modal {
    .icons-items {
      margin-top: 40px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      img {
        height: 32px;

      }

      .icons {
        width: auto;
        height: auto;
        margin-right: 10px;
      }

      .item {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        position: relative;
        margin-right: 40px;
        font-size: 14px;
        line-height: 19px;

        &:last-child {
          &:after {
            display: none;
            margin: 0;
          }
        }

        &:after {
          content: "";
          display: block;
          width: 2px;
          height: 100%;
          background-color: #171717;
          position: absolute;
          right: -20px;
          top: 0;
        }

      }
    }

    h2 {
      text-transform: uppercase;
      font-size: 28px;
      line-height: 30px;
    }

    .content-modal {
      width: 70%;
    }

    .button-holder {
      display: block;

      .default-btn {
        margin: 0;
      }
    }

    .modal-wrapper {

    }
  }

  &.age-modal, &.consultant-modal {
    .modal-wrapper {
      width: 700px;
    }
  }


  .content-modal {
    padding: 50px 30px;
    position: relative;
    z-index: 3;
  }

  .modal-wrapper {
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
    z-index: 10;
  }
}

@media (max-width: 767px) {
  .info-modal.consultant-modal h2 {
    font-size: 18px;
    line-height: 22px;
  }

  .info-modal.consultant-modal .content-modal {
    padding-bottom: 140px;
    width: 100%;
  }

  .info-modal.consultant-modal .icons-items {
    margin-top: 20px;
  }

  .info-modal.consultant-modal .icons-items .item:after {
    right: -15px;
  }

  .info-modal.consultant-modal .icons-items .item {
    margin-right: 30px;
    font-size: 12px;
    line-height: 17px;
  }

  .info-modal .content-modal {
    padding: 30px 15px;
  }

  .info-modal.age-modal .content-modal {
    padding: 64px 30px 62px;
  }


  .info-modal .modal-wrapper {
    bottom: 0;
    width: 100% !important;
    border-radius: 15px 15px 0px 0px;
    position: absolute;
    left: 0;
  }

  .info-modal.age-modal .modal-wrapper {

  }
}

@media (max-width: 520px) {
  .info-modal.consultant-modal .modal-picture .img-mob {
    display: block;
  }

  .info-modal.consultant-modal .modal-picture .img-desc {
    display: none;
  }

  .info-modal .button-holder .default-btn {
    margin: 10px auto;
  }

  .info-modal .button-holder {
    display: block;
  }

  .info-modal.age-modal .logo img {
    max-height: 20px;
  }

  .info-modal.age-modal .logo {
    margin-bottom: 15px;
  }

  .info-modal h2, .info-modal .h2 {
    margin-bottom: 10px;
  }
}
</style>