import { reactive, computed, triggerRef, watchEffect, ref } from 'vue'

class Analytic {
  install(app, options) {
    app.config.globalProperties.$Code = (code) => {
      fetch('/public/code/' + code)
    }
    
    app.config.globalProperties.$AddToCart = (item) => {
      console.log('$AddToCart', item)
      
      if (window.fbq) {
        window.fbq('track', 'AddToCart', {
          content_name    : item.title,
          content_category: item.category,
          content_ids     : [item.id],
          content_type    : 'product',
          value           : item.price * item.count
        });
      }
      if (window.gtag) {
        window.gtag('event', 'add_to_cart', {
          items : [{id: item.id, name: item.name, quantity: item.count, price: item.price}],
          category: item.category,
          value : item.price
        });
      }
    };
    
    app.config.globalProperties.$OrderPage = () => {
      console.log('$OrderPage')
      
      if (window.fbq) {
        window.fbq('track', 'InitiateCheckout');
      }
      
      if (window.gtag) {
        window.gtag('event', 'screen_view', {
          app_name   : 'Order',
          screen_name: 'Order'
        });
      }
    };
    
    app.config.globalProperties.$ViewCategory = (category) => {
      console.log('$ViewCategory', category)
      
      if (window.fbq) {
        window.fbq('track', 'ViewContent', {
          content_name    : category.name,
          content_category: category.name,
          content_type    : 'category'
        });
      }
      
      if (window.gtag) {
        window.gtag('event', 'screen_view', {
          app_name   : category.name,
          screen_name: category.name
        });
      }
      return undefined
    };
    
    app.config.globalProperties.$Purchase = (busked) => {
      console.log('$Purchase', busked)
      
      if (window.fbq) {
        window.fbq('track', 'Purchase', Array.from(busked).reduce((prev, curr) => {
          return prev + curr.price * curr.count
        }, 0))
      }
      
      if (window.gtag) {
        window.gtag('event', 'begin_checkout', {
          items: busked.map(i => {return {id: i.id, name: i.title, value: i.price * i.count}})
        });
      }
    };
    
    app.config.globalProperties.$ViewItem = (item) => {
      console.log('$ViewItem', item)
      
      if (window.fbq) {
        window.fbq('track', 'ViewContent', {
          content_name    : item.title,
          content_category: item.category,
          content_type    : 'product',
          content_ids     : [item.id]
        });
      }
      
      if (window.gtag) {
        window.gtag('event', 'view_item', {
          items: [{id: item.id, name: item.title}]
        });
      }
      return undefined
    };
  }
}

export default Analytic
