<template>
  <input type="text" @focus="focus" @blur="blur" class="form-control custom-input" placeholder="..."
         v-model="selectedText" @input="debounceSearch">
  <ul v-if="init" :class="{ 'show': show || showForce }">
    <li v-for="(item, index) in result" :key="index" @click="select(item)">
      {{ item.Description }}
    </li>
    <li v-if="noResult">
      {{ $static('search.empty-res', 'Нема результату.') }}
    </li>
    <li v-if="loading">
      {{ $static('search.load-res', 'Завантажую') }}
    </li>
  </ul>
</template>

<style scoped>
ul {
  position   : absolute;
  background : white;
  width      : 100%;
  max-height : 150px;
  overflow-y : scroll;
  opacity    : 0;
  z-index    : -11;
  transition : z-index 0.1s;
  cursor     : pointer;
}

.show {
  z-index : 1111;
  opacity : 1;
}
</style>

<script>
export default {
  name  : 'SearchSelect',
  // events: ['change'],
  props : ['url', 'preload', 'value', 'search'],
  emits : ['errorCleaningFocus', 'change'],
  data() {
    return {
      debounce    : undefined,
      show        : false,
      showForce   : false,
      result      : [],
      selected    : undefined,
      selectedText: '',
      loading     : false,
      noResult    : false,
      init        : false,
      checkTimeout: undefined
    }
  },
  mounted() {
    if (this.preload) {
      this.load();
    }
    if (this.value) {
      this.selected     = this.value;
      this.selectedText = this.value.Description;
    }
  },
  methods: {
    focus() {
      this.$emit('errorCleaningFocus');
      this.show = true;

      if (this.preload) {
        this.init = true;
      }
      if (this.result?.length === 0) {
        this.load();
        console.log('loading...')
      }
    },
    blur() {
      if (this.checkTimeout) {
        clearTimeout(this.checkTimeout)
      }
      this.checkTimeout = setTimeout(() => {
        this.show = false
        if (this.selected === undefined) {
          this.selectedText = ''
          this.result       = []
        }
      }, 300)
    },
    load(query) {
      // if (!query) {
      //   this.result = [];
      //   return;
      // }
      this.loading  = true;
      this.noResult = false;

      this.search(query).then(result => {
        this.init = true;
        if (Array.isArray(result) && result.length) {
          this.result = result;
        } else {
          this.result   = [];
          this.noResult = true;
        }

        this.loading = false;
      }).catch(error => {
        this.loading = false;
      });
    },
    select(item) {
      // console.log('select', item)

      this.show      = false;
      this.showForce = false;

      this.selected     = item;
      this.selectedText = item.Description;
      this.$emit('change', item);
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.load(event.target.value)
      }, 600)
    }
  }
}
</script>
