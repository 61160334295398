<template>
  <div class="category-panel" id="category_panel" v-if="menu_type === undefined">
    <div class="category-panel-holder">
      <div class="panel-name">{{$static('categories.title', 'категорії')}}:</div>
      <ul class="categories-list" @mousedown="e => mouseDownHandlerTarget(e,'categories-list')">
        <li>
          <router-link :to="{name:'home'}" :exact-active-class="'active'">
            <span class="text">{{$static('menu.home', 'Головна')}}</span>
          </router-link>
        </li>

        <li v-for="(category,index) in categories" :key="'category-'+index">
          <router-link :to="'/catalog/'+category.info.url" :activeClass="'active'">
            <span class="text">{{$dynamic(category, category.name, 'name')}}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <AdvancedMenu :categories="categories" :menu="menu" v-else-if="menu_type==='advanced' && menu"/>
</template>

<script>
import AdvancedMenu from '@/components/AdvancedMenu';

export default {
    name: 'Categories',
    components: {AdvancedMenu},
    computed: {
      categories() {
        return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a,b) => a.order - b.order)
      },
      menu_type(){
        return this.$store.getters.getSettings ? this.$store.getters.getSettings.menu_type : undefined
      },
      menu(){
        return this.$store.getters.getPages ? this.$store.getters.getPages.menu : undefined
      }
    },
    methods   : {
      fancyClick(e) {
        if (window.change) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      mouseDownHandlerTarget(e, targetClass) {
        let target = e.target;
        while (!target.className.includes(targetClass)) {
          target = target.parentElement
        }
        window.ele = target;
        window.mouseDownHandler(e)
      },
    },
    mounted() {
      if(this.menu_type === undefined) {
        (function ($) {
          'use strict';
          // $('#category_panel').stick_in_parent({offset_top: 0});
          // $('#subcategory_panel').stick_in_parent({offset_top: 60});
        })(window.$);
      }
    }

}
</script>

