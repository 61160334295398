<template>
  <div class="title-heading">
    <h1>{{$static('cabinet.title1', 'Налаштування доставки')}}</h1>
  </div>
  <div class="personal-delivery  d-none">
    <div class="personal-delivery-holder">
      <div class="map-wrapper" style="height: 200px;background-color: #f5f5f5;border-radius: 20px;">

      </div>
      <div class="panel-bordered delivery-form">
        <div class="personal-delivery-left">
          <div class="form-group">
            <label class="label-custom">{{$static('cabinet.field1', 'Під‘їзд')}} <span>*</span></label>
            <input type="text" class="form-control custom-input"
                   :placeholder="$static('cabinet.front-door-num', 'Введіть номер під’їзду')"
            >
          </div>
          <div class="form-inline">
            <div class="form-group">
              <label class="label-custom">{{$static('cabinet.field2', 'Поверх')}} <span>*</span></label>
              <input type="text" class="form-control custom-input">
            </div>
            <div class="form-group">
              <label class="label-custom">{{$static('cabinet.field3', 'Квартира')}}</label>
              <input type="text" class="form-control custom-input">
            </div>
          </div>
        </div>
        <div class="personal-delivery-right">
          <div class="form-group mb-0">
            <label class="label-custom">{{$static('cabinet.field4', 'Коментар для кур’єра')}}</label>
            <textarea class="form-control custom-input"
                      :placeholder="$static('cabinet-delivery.message-text', 'Текст повідомлення')">
            </textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="button-holder">
      <button class="default-btn btn-no-icon">{{$static('cabinet.btn-save', 'Зберегти зміни')}}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CabinetDelivery'
}
</script>
