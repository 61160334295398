<template>
  <div class="title-heading">
    <h1>{{$static('cabinet.title2', 'Історія замовлень')}}</h1>
  </div>
  <div class="personal-order-list-table">
    <div class="personal-order-list-top">
      <div class="name name-date_status">{{$static('cabinet.tn1', 'Дата/статус:')}}</div>
      <div class="name name-price">{{$static('cabinet.tn2', 'Сума замовлення:')}}</div>
      <div class="name name-product">{{$static('cabinet.tn3', 'Товар:')}}</div>
    </div>
    <!--personal area order list item-->
    <HistoryItemRow v-for="(order, index) in list" :key="index" :order="order"/>
    <!--end personal area order list item-->
    <div v-if="!list || list.length === 0">
      {{$static('cabinet.no-order', 'Немає замовлень')}}
    </div>
  </div>
</template>
<script>
import HistoryItemRow from '@/components/HistoryItemRow';

export default {
  name      : 'CabinetHistory',
  components: {HistoryItemRow},
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getHistory()
  },
  computed: {
    payment() {
      return this.$store.getters.getPayment
    },
    delivery() {
      return this.$store.getters.getDelivery
    }
  },
  methods : {
    getHistory() {
      let ok        = false
      let userInfo  = localStorage.getItem('user_info')
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok        = true
      }
      if (!ok) {
        this.$router.push({name: 'AuthRequire'})
        return
      }
      return fetch(
          '/public/history',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.list = json.result
        }
        if (json?.error) {
          window.localStorage.removeItem('user_info')
        }
      })
    }
  }
}
</script>
