<template>

  <div v-html="html" class="default-wrapper wrapper"></div>
</template>

<script>
export default {
  name : 'Page',
  props: ['pagename'],
  data() {return {html: '', lang: '', page: undefined}},

  computed: {
    isUpdate() {
      return this.$updateLang()
    },
    path() {
      return this.$route.path;
    }
  },
  watch   : {
    isUpdate() {
      this.getPage()
    },
    pagename(page) {
      this.getPage(page)
    }
  },
  methods : {
    getPage(page) {
      let query = '';
      let lang  = localStorage.getItem('$lang');
      if (this.lang !== lang || page != this.page) {
        if (lang) {
          query = '?lang=' + lang
        }
        fetch(`/public/template/${this.pagename}` + query).then(response => response.json()).then(data => {
          let name        = this.$route.name;
          let settings    = data && data.settings ? data.settings : {};
          let title       = settings.meta_title ? settings.meta_title : name;
          let description = settings.meta_description ? settings.meta_description : '';
          let image       = settings.image ? `${window.location.protocol}//${window.location.host}${settings.image}` : '';

          document.querySelector('title').innerHTML = title;
          document.querySelector('meta[property="og:title"]').setAttribute('content', title);
          document.querySelector('meta[name="description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:image"]').setAttribute('content', image);

          this.html = data.template;
        })
      }
      this.page = page
      this.lang = lang;
    }
  },
  mounted() {
    this.getPage(this.pagename);
  }
}
</script>

<style scoped>

</style>