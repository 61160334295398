<template>
  <div class="main-reviews-container">
    <section class="reviews-hero-section">
      <h1 class="store-reviews-head">{{ $static('reviews.title', 'Відгуки про Магазин') }}</h1>
      <h2 class="total-mark-head">{{ $static('reviews.total-mark', 'Загальна оцінка') }}</h2>
      <div class="raiting-container">
        <svg width="22" height="18" viewBox="0 0 22 18" class="raiting-star unfilled" v-for="starPosition in 5"
             :key="starPosition"> fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17.497 17.9939L17.4335 17.498C17.3881 17.5038 17.3455 17.4967 17.3134 17.4829L17.3091 17.481L11.4778 15.0301C11.1747 14.9026 10.8253 14.9026 10.5221 15.0301L4.69089 17.481L4.68824 17.4822C4.64016 17.5027 4.58244 17.5055 4.53089 17.4905C4.47868 17.4752 4.45787 17.45 4.45269 17.4409C4.45229 17.4402 4.45197 17.4396 4.45171 17.4391L5.36731 11.9419C5.36734 11.9417 5.36738 11.9415 5.36742 11.9413C5.42771 11.5825 5.28507 11.2408 5.02724 11.0132L0.517289 7.0277L0.517294 7.02769L0.515239 7.02589C0.50745 7.01909 0.503388 7.01379 0.501409 7.01072C0.503461 7.00751 0.507863 7.00175 0.516599 6.99435L0.517376 6.99368C0.53532 6.97841 0.567874 6.96116 0.611961 6.95476L0.611962 6.95476L0.613903 6.95447L7.0019 6.00104C7.00205 6.00102 7.0022 6.001 7.00235 6.00098C7.32715 5.95307 7.63633 5.77376 7.81691 5.47975C7.81704 5.47954 7.81717 5.47933 7.8173 5.47912L10.8529 0.561154L10.8529 0.561159L10.8544 0.558618C10.8609 0.548031 10.884 0.52248 10.9375 0.508157C10.9903 0.494025 11.0477 0.498449 11.0943 0.519662L11.0951 0.520027C11.1277 0.534791 11.1414 0.551896 11.1454 0.558467L11.1454 0.558472L11.1471 0.561154L14.1827 5.47912C14.1827 5.47927 14.1828 5.47942 14.1829 5.47957C14.3635 5.7737 14.6728 5.95307 14.9977 6.00098C14.9978 6.001 14.9979 6.00102 14.9981 6.00104L21.3861 6.95448L21.3886 6.95484C21.4491 6.96356 21.4818 6.98981 21.4941 7.00475C21.4961 7.00709 21.4975 7.00906 21.4984 7.01062C21.4963 7.01403 21.4922 7.01938 21.4845 7.02605L21.4827 7.0277L16.9727 11.0132C16.9726 11.0133 16.9726 11.0133 16.9725 11.0134C16.7148 11.241 16.5723 11.5826 16.6325 11.9412C16.6326 11.9414 16.6326 11.9417 16.6326 11.9419L17.5483 17.4397C17.5473 17.4416 17.5454 17.4444 17.5422 17.4482C17.5288 17.4639 17.4944 17.4901 17.4329 17.498L17.497 17.9939Z"
              stroke="#FFC828" fill="none"/>
        </svg>
        <div class="raiting-container filled" :style="{ width: `${totalRaiting * 20}%` }">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" class="raiting-star filled"
               v-for="starPosition in 5" :key="starPosition" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5726 0.298533L14.6089 5.21773C14.7027 5.3707 14.8735 5.47734 15.0708 5.50637L21.4599 6.45995C21.8125 6.51075 22.0499 6.79884 21.991 7.10311C21.969 7.21645 21.9069 7.32085 21.8138 7.40236L17.3036 11.3881C17.1626 11.5126 17.0966 11.6862 17.1257 11.8587L18.0418 17.3591C18.0942 17.6639 17.851 17.9481 17.497 17.9939C17.3664 18.0106 17.2331 17.9928 17.1153 17.942L11.284 15.491C11.1048 15.4157 10.8952 15.4157 10.716 15.491L4.88463 17.942C4.56437 18.0788 4.17618 17.9654 4.01831 17.6885C3.96008 17.5863 3.93873 17.4713 3.95814 17.3591L4.87428 11.8587C4.90339 11.6862 4.8374 11.5126 4.69636 11.3881L0.186191 7.40236C-0.0648409 7.18295 -0.0616059 6.82898 0.193308 6.61292C0.287769 6.53253 0.409403 6.47893 0.540095 6.45995L6.92912 5.50637C7.12645 5.47734 7.29726 5.3707 7.39107 5.21773L10.4274 0.298533C10.5937 0.025524 10.9851 -0.0794367 11.3015 0.0646051C11.4173 0.117085 11.5117 0.198597 11.5726 0.298533Z"
                fill="#FFC828"/>
          </svg>

        </div>
      </div>
      <p class="reviews-count-text">{{ $static('reviews.count1', 'всього') }} <span>{{ totalReviews }}</span> {{
          $static(
              'reviews.count2',
              'відгуків'
          )
        }}</p>
      <button class=" write-review-btn" type="button" @click="openModal">{{ $static('reviews.write-new', 'Написати відгук') }}</button>
    </section>

    <section class="reviews-section">
      <h2 class="reviews-header">{{ $static('reviews.title2', 'Відгуки:') }}</h2>

      <ul class="reviews-list" @scroll="handleReviewsListScroll">
        <ReviewItem v-for="(review, reviewNumber) in  reviews " :key="reviewNumber" :review="review"
                    :reviewNumber="reviewNumber" :moderatedReviewsNumber="moderatedReviewsNumber"/>
      </ul>

      <div class="load-more-reviews" v-if="isLoadingReviews">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path
              d="M15.1634 8.73898C14.6358 8.66188 14.1673 9.00919 14.0916 9.50434C13.9016 10.748 13.304 11.8827 12.3632 12.7862C9.96888 15.0857 6.07593 15.0839 3.68255 12.7862C1.28965 10.4876 1.28965 6.74739 3.68255 4.44922C4.58269 3.58518 5.71903 3.02182 6.9908 2.81673C7.53772 2.71705 8.12573 2.69982 8.73583 2.76621C9.57354 2.85882 10.3533 3.12576 11.0648 3.51769L9.83525 3.71933C9.32157 3.80396 8.97562 4.27253 9.06421 4.76633C9.1426 5.20833 9.542 5.52053 9.99391 5.52053C10.0465 5.52053 10.1 5.51654 10.1544 5.50765L13.4506 4.96642C13.6968 4.92569 13.9173 4.79281 14.0621 4.59617C14.2069 4.39997 14.2642 4.15634 14.2217 3.91897L13.6572 0.753672C13.5705 0.260332 13.089 -0.0701023 12.567 0.0127105C12.0533 0.0973373 11.7074 0.566368 11.796 1.06016L11.9445 1.89328C11.0304 1.40075 10.0221 1.08211 8.95069 0.963652C8.1483 0.875941 7.3699 0.90034 6.65742 1.03141C5.01713 1.29536 3.52672 2.03369 2.34703 3.16658C-0.782344 6.17206 -0.782344 11.0625 2.34703 14.0687C3.91167 15.5719 5.96751 16.3231 8.02288 16.3231C10.0787 16.3231 12.1335 15.572 13.6987 14.0687C14.9282 12.888 15.7103 11.4008 15.9602 9.76829C16.0359 9.27223 15.6789 8.81154 15.1634 8.73898Z"
              fill="#353535"/>
        </svg>
      </div>
    </section>

  </div>
  <section class="add-review-section-only-mobile">
    <button class="write-review-btn" type="button" @click="openModal">
      {{ $static('reviews.write-new', 'Написати відгук') }}
    </button>
  </section>

  <!--! Modal for review with logic -->
  <div class="frame-modal-wrapper-bg" :class="{'is-visible': isCreateReviewModalOpen }" @click.self="closeCreateReviewModal">
    <section class="create-review-modal">
      <section class="header">
        <b>Написати відгук</b>
        <svg class="close-modal-btn" width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg" @click.self="closeCreateReviewModal">
          <path d="M15 5L5 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 5L15 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </section>

      <section class="raiting-section" style="position: relative;">
        <p class="help-text help-text__in-raiting" v-if="modalErrors.notSelectedRaitingError">
          {{$static('reviews.choose-rating', 'Оберіть оцінку')}}</p>
        <div class="raiting-container in-modal">
          <svg data-modal-review-raiting width="22" height="18" viewBox="0 0 22 18" class="raiting-star unfilled in-modal"
               v-for="(starPosition, modalStarIndex) in 5" :key="starPosition" @click="handleStarClick(modalStarIndex)"
               @mouseenter="handleStarHover(modalStarIndex)" @mouseleave="handleStarUnHover(modalStarIndex)"
               :style="{ fill: starActive[modalStarIndex] ? '#FFC828' : 'none' }">
            <path
                d="M17.497 17.9939L17.4335 17.498C17.3881 17.5038 17.3455 17.4967 17.3134 17.4829L17.3091 17.481L11.4778 15.0301C11.1747 14.9026 10.8253 14.9026 10.5221 15.0301L4.69089 17.481L4.68824 17.4822C4.64016 17.5027 4.58244 17.5055 4.53089 17.4905C4.47868 17.4752 4.45787 17.45 4.45269 17.4409C4.45229 17.4402 4.45197 17.4396 4.45171 17.4391L5.36731 11.9419C5.36734 11.9417 5.36738 11.9415 5.36742 11.9413C5.42771 11.5825 5.28507 11.2408 5.02724 11.0132L0.517289 7.0277L0.517294 7.02769L0.515239 7.02589C0.50745 7.01909 0.503388 7.01379 0.501409 7.01072C0.503461 7.00751 0.507863 7.00175 0.516599 6.99435L0.517376 6.99368C0.53532 6.97841 0.567874 6.96116 0.611961 6.95476L0.611962 6.95476L0.613903 6.95447L7.0019 6.00104C7.00205 6.00102 7.0022 6.001 7.00235 6.00098C7.32715 5.95307 7.63633 5.77376 7.81691 5.47975C7.81704 5.47954 7.81717 5.47933 7.8173 5.47912L10.8529 0.561154L10.8529 0.561159L10.8544 0.558618C10.8609 0.548031 10.884 0.52248 10.9375 0.508157C10.9903 0.494025 11.0477 0.498449 11.0943 0.519662L11.0951 0.520027C11.1277 0.534791 11.1414 0.551896 11.1454 0.558467L11.1454 0.558472L11.1471 0.561154L14.1827 5.47912C14.1827 5.47927 14.1828 5.47942 14.1829 5.47957C14.3635 5.7737 14.6728 5.95307 14.9977 6.00098C14.9978 6.001 14.9979 6.00102 14.9981 6.00104L21.3861 6.95448L21.3886 6.95484C21.4491 6.96356 21.4818 6.98981 21.4941 7.00475C21.4961 7.00709 21.4975 7.00906 21.4984 7.01062C21.4963 7.01403 21.4922 7.01938 21.4845 7.02605L21.4827 7.0277L16.9727 11.0132C16.9726 11.0133 16.9726 11.0133 16.9725 11.0134C16.7148 11.241 16.5723 11.5826 16.6325 11.9412C16.6326 11.9414 16.6326 11.9417 16.6326 11.9419L17.5483 17.4397C17.5473 17.4416 17.5454 17.4444 17.5422 17.4482C17.5288 17.4639 17.4944 17.4901 17.4329 17.498L17.497 17.9939Z"
                stroke="#FFC828"/>
          </svg>
        </div>
        <div class="raiting-container-text">
          <div><span>{{ $static('reviews.rating1', 'погано') }}</span></div>
          <div><span>{{ $static('reviews.rating2', 'так собі') }}</span></div>
          <div><span>{{ $static('reviews.rating3', 'нормально') }}</span></div>
          <div><span>{{ $static('reviews.rating4', 'добре') }}</span></div>
          <div><span>{{ $static('reviews.rating5', 'чудово') }}</span></div>
        </div>

      </section>

      <div class="section-line-devider"></div>

      <section class="review-text-section">
        <textarea class="modal-review-textarea" id="" :placeholder="$static('reviews.write-eny', 'напишіть що-небудь')"
                  v-model="createdReviewData.comment"></textarea>
        <p class="leave-comment-сall-to-action" v-if="tags.length > 0">{{ $static('reviews.compliment', 'Залишіть комплімент:') }}</p>
        <div class="review-block__tag-container review-block__tag-container--in-modal">
          <template v-for="(reviewTag, reviewTagIndex) in tags" :key="reviewTagIndex">
            <input class="review-tag-checkbox" type="checkbox" :value="reviewTag.toLowerCase().trim()"
                   :id="'checkbox_' + reviewTag.toLowerCase().trim() + reviewTagIndex"
                   @change="handleReviewTagClick(reviewTag, $event)">
            <label :for="'checkbox_' + reviewTag.toLowerCase().trim() + reviewTagIndex"
                   class="review-block__tag  review-block__tag--in-modal">
              {{ reviewTag.toLowerCase().trim() }}
            </label>
          </template>
        </div>
      </section>

      <div class="section-line-devider"></div>

      <section class="photo-section">
        <div class="modal-review__photo-container">
          <div class="modal-review__photo-wrapper"
               v-for="(reviewPhoto, reviewPhotoIndex) in previewPhotosURLArray"
               :key="reviewPhotoIndex">
            <img class="modal-review__photo" :src="reviewPhoto" alt="фото в отзыве">
            <div class="modal-review__photo-overlay"></div>
            <svg class="modal-review__photo-delete-btn" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                 viewBox="0 0 20 20" fill="none" @click="removeReviewPhoto(reviewPhotoIndex)">
              <g filter="url(#filter0_d_2187_860)">
                <path
                    d="M7.70768 2.5C7.70768 2.15482 7.9875 1.875 8.33268 1.875H11.666C12.0112 1.875 12.291 2.15482 12.291 2.5V3.125H15.8327C16.1779 3.125 16.4577 3.40482 16.4577 3.75C16.4577 4.09518 16.1779 4.375 15.8327 4.375H4.16602C3.82084 4.375 3.54102 4.09518 3.54102 3.75C3.54102 3.40482 3.82084 3.125 4.16602 3.125H7.70768V2.5Z"
                    fill="#C1272D"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.19926 6.62065C5.22271 6.40964 5.40107 6.25 5.61338 6.25H14.3853C14.5976 6.25 14.776 6.40964 14.7994 6.62065L14.9662 8.12178C15.2669 10.8276 15.2669 13.5584 14.9662 16.2642L14.9498 16.412C14.8403 17.3975 14.0765 18.1833 13.0946 18.3207C11.0411 18.6082 8.95759 18.6082 6.90413 18.3207C5.92216 18.1833 5.15839 17.3975 5.04889 16.412L5.03247 16.2642C4.73183 13.5584 4.73183 10.8276 5.03247 8.12178L5.19926 6.62065ZM12.108 10.3914C12.352 10.6355 12.352 11.0312 12.108 11.2753L10.8832 12.5L12.108 13.7247C12.352 13.9688 12.352 14.3645 12.108 14.6086C11.8639 14.8527 11.4682 14.8527 11.2241 14.6086L9.99935 13.3839L8.77463 14.6086C8.53055 14.8527 8.13483 14.8527 7.89075 14.6086C7.64667 14.3645 7.64667 13.9688 7.89075 13.7247L9.11547 12.5L7.89074 11.2753C7.64666 11.0312 7.64666 10.6355 7.89074 10.3914C8.13482 10.1473 8.53055 10.1473 8.77462 10.3914L9.99935 11.6161L11.2241 10.3914C11.4682 10.1473 11.8639 10.1473 12.108 10.3914Z"
                      fill="#C1272D"/>
              </g>
              <defs>
                <filter id="filter0_d_2187_860" x="2.54102" y="0.875" width="14.916" height="18.6614"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="0.5"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_860"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_860" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <label class="modal-review__photo-upload-btn-immitator" for="review-photo-input">
          <div class="modal-review__photo-upload-content-wrapper">

            <svg class="modal-review__upload-photo-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                 viewBox="0 0 10 10" fill="none">
              <g clip-path="url(#clip0_1792_1069)">
                <path
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
                    fill="#2CB014"/>
                <path d="M5 2.6001V7.6001" stroke="white" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.5 5H2.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1792_1069">
                  <rect width="10" height="10" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <span>{{ $static('reviews.load-img', 'Завантажити зображення') }}</span>
          </div>
          <input type="file" name="review-photo-input" id="review-photo-input" accept="image/*" multiple
                 ref="reviewPhotosInput" @change="addReviewPhotos">
          <p class="help-text" v-if="modalErrors.reviewPhotoUploadError">
            {{$static('reviews.help-text1', 'Помилка завантаження фотографії')}}
          </p>
          <p class="help-text" v-if="modalErrors.maxReviewPhotosError">
            {{$static('reviews.help-text21', 'Перевищено максимальну кількість зображень для всіх ваших відгуків')}} ({{ this.$store.getters.getSettings.maxReviewPhotos || 0 }} {{ $static('reviews.help-text22', 'зображень)') }}
          </p>
          <p class="help-text" v-if="modalErrors.notAuthPhotosError">
            {{$static('reviews.help-text3', 'Для можливості додати фото відгуку потрібно авторизуватись')}}
          </p>
        </label>
      </section>

      <div class="section-line-devider"></div>

      <section class="send-btn-section">
        <button class="write-review-btn write-review-btn--in-modal" type="button" @click="postReview($event)"
                :style="{ opacity: isReviewSending ? '0.5' : '1' }">{{ $static('reviews.sending', 'Відправити') }}
        </button>
      </section>
    </section>
  </div>

  <!--! Modal after review sending -->
  <div class="frame-modal-wrapper-bg" :class="{ 'is-visible': isReviewSent }" @click.self="closeModalAfterReviewSend">
    <section class="review-after-send-modal">
      <svg class="close-modal-btn close-modal-btn__review-after-send-modal" width="20" height="20" viewBox="0 0 20 20"
           fill="none" xmlns="http://www.w3.org/2000/svg" @click.self="closeModalAfterReviewSend">
        <path d="M15 5L5 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 5L15 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="review-after-send-modal__content-wrapper" v-if="isReviewSentSuccessfully">
        <b>{{ $static('reviews.as-thanks', 'Дякую!') }}</b>
        <p>{{ $static('reviews.as-msg', 'Ваш відгук надіслано на модерацію і незабаром буде опубліковано') }}</p>
      </div>
      <div class="review-after-send-modal__content-wrapper" v-else>
        <b class="send-modal-error">{{ $static('reviews.as-thanks-error', 'Помилка!') }}</b>
        <p>{{ $static('reviews.as-not-send', 'Відгук не був надісланий') }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import { fixBodyPadding } from '@/utils/fixBodyPadding.js';
import ReviewItem from '@/components/ReviewItem.vue'

export default {
  name      : 'Reviews',
  components: {ReviewItem},
  data() {
    return {
      reviews                 : [],
      totalReviews            : 0,
      moderatedReviewsNumber  : 0,
      isCreateReviewModalOpen : false,
      isReviewSent            : false,
      isReviewSentSuccessfully: false,
      isReviewSending         : false,
      starActive              : [false, false, false, false, false],
      createdReviewData       : {
        name              : '',
        reviewRaiting     : undefined,
        comment           : '',
        tags              : [],
        photo             : [],
        reviewTime        : undefined,
        reviewAuthorAvatar: undefined

      },
      previewPhotosURLArray   : [],
      reviewPhotoFiles        : [],
      isLoadingReviews        : false,
      loadMore                : undefined,
      modalErrors             : {
        maxReviewPhotosError   : false,
        reviewPhotoUploadError : false,
        notSelectedRaitingError: false
      }
    }
  },
  computed: {
    totalRaiting() {
      return this.$store.getters.getSettings.rating ? this.$store.getters.getSettings.rating : 4.8
    },
    tags() {
      if (typeof this.$store.getters.getSettings.tags === 'string') {
        return this.$store.getters.getSettings.tags.split(',');
      }
      return []
    }
  },

  methods: {
    openModal() {
      this.isCreateReviewModalOpen = true;
      fixBodyPadding();
      document.body.classList.add('no-scroll');
    },

    openModalAfterReviewSent(status) {
      this.isReviewSent = true;
      fixBodyPadding();
      document.body.classList.add('no-scroll');

      if (status == 200) { this.isReviewSentSuccessfully = true; }
    },

    closeCreateReviewModal(event) {

      if (!event.target.classList.contains('write-review-btn--in-modal')) {
        this.createdReviewData.photo.forEach((_, revPhotoIdx) => { this.removeReviewPhoto(revPhotoIdx); });
      }

      this.isCreateReviewModalOpen = false;
      this.isReviewSending         = false;
      this.starActive              = [false, false, false, false, false];
      this.createdReviewData       = {
        name         : '',
        reviewRaiting: undefined,
        comment      : '',
        tags         : [],
        photo        : [],
        reviewTime   : undefined
      };
      this.previewPhotosURLArray   = [];
      this.reviewPhotoFiles        = [];

      this.modalErrors = {
        notAuthPhotosError     : false,
        maxReviewPhotosError   : false,
        reviewPhotoUploadError : false,
        notSelectedRaitingError: false
      };

      this.resetReviewTagsCheckboxes();

      fixBodyPadding();
      document.body.classList.remove('no-scroll');
    },

    closeModalAfterReviewSend() {
      this.isReviewSent             = false;
      this.isReviewSentSuccessfully = false;

      fixBodyPadding();
      document.body.classList.remove('no-scroll');
    },
    //helpers for work with reviews data________________________________________________________________

    handleStarClick(index) {
      this.modalErrors = {
        notAuthPhotosError     : false,
        maxReviewPhotosError   : false,
        reviewPhotoUploadError : false,
        notSelectedRaitingError: false
      };

      this.starActive.fill(false);
      this.starActive.fill(true, 0, index + 1);
      this.createdReviewData.reviewRaiting = index + 1;
    },
    handleStarHover(index) {
      this.starActive.fill(true, 0, index + 1);
    },
    handleStarUnHover() {
      if (this.createdReviewData.reviewRaiting === 0) {
        this.starActive = this.starActive.map(() => false);
      } else {
        this.starActive = this.starActive.map((_, i) => i < this.createdReviewData.reviewRaiting);
      }
    },

    setReviewTime() {
      const reviewTime = new Date();
      const timeOffset = -reviewTime.getTimezoneOffset();

      this.createdReviewData.reviewTime = ((reviewTime.getTime() + timeOffset * 60 * 1000) / 1000).toFixed();
    },

    handleReviewTagClick(tag, event) {
      tag = tag.toLowerCase().trim();

      const isChecked = event.target.checked;

      if (!isChecked) {
        const tagIndex = this.createdReviewData.tags.findIndex(revTag => revTag.text === tag.text)
        this.createdReviewData.tags.splice(tagIndex, 1);
      } else {
        this.createdReviewData.tags.push(tag);
      }
    },

    resetReviewTagsCheckboxes() {
      const checkboxArray = Array.from(document.querySelectorAll('.review-tag-checkbox'));
      checkboxArray.forEach(el => el.checked = false);
    },

    async addReviewPhotos(event) {
      this.modalErrors.notAuthPhotosError     = false;
      this.modalErrors.maxReviewPhotosError   = false;
      this.modalErrors.reviewPhotoUploadError = false;

      let reviewPhotos = [...event.target.files];

      // Обработка одинаковых по имени фоток при добавлении
      if (this.reviewPhotoFiles.length > 0) {
        const oldReviewPhotoNamesArray = [...this.reviewPhotoFiles].map(revPhoto => revPhoto.name);

        reviewPhotos = [...reviewPhotos].filter(revPhoto => !oldReviewPhotoNamesArray.includes(revPhoto.name));
      }
      // -----------------

      if ((this.reviewPhotoFiles.length + reviewPhotos.length) > this.$store.getters.getSettings.maxReviewPhotos) {
        event.target.value                    = null;
        this.modalErrors.maxReviewPhotosError = true;

        return;
      }

      for (const revPhoto of reviewPhotos) {
        try {
          const photoStorageURL = await this.sendReviewPhoto(revPhoto);

          const reader  = new FileReader();
          const promise = new Promise((resolve) => {
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(revPhoto);
          });

          this.previewPhotosURLArray.push(await promise);
          this.reviewPhotoFiles.push(revPhoto);
          this.createdReviewData.photo.push(photoStorageURL);

        } catch (error) {
          this.modalErrors.reviewPhotoUploadError = true;
        }
      }
    },

    async sendReviewPhoto(file) {
      const formData = new FormData();

      formData.append('user', JSON.stringify(this.$store.getters.getUserInfo));
      formData.append('file', file);

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.addEventListener('load', () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const response = JSON.parse(xhr.responseText);
              if (response.error) {
                if (response.error === 'not auth') {
                  this.modalErrors.notAuthPhotosError = true;
                }
                if (response.error === 'too much files') {
                  this.modalErrors.maxReviewPhotosError = true;
                }
                reject(`Ошибка загрузки файла ${file.name}: ${response.error}`);
              } else {
                resolve(response.url);
              }
            } catch (parseError) {
              reject(`Ошибка разбора JSON в ответе сервера: ${parseError}`);
            }
          } else {

            reject(`Ошибка загрузки файла ${file.name}: ${xhr.statusText}`);
          }
        });

        xhr.addEventListener('error', () => {

          reject(`Ошибка загрузки файла ${file.name}: Сетевая ошибка`);
        });

        xhr.addEventListener('abort', () => {
          reject(`Загрузка файла ${file.name} была прервана`);
        });

        xhr.open('POST', '/public/review/upload');
        xhr.send(formData);
      });
    },

    async removeReviewPhoto(photoIdx) {
      try {
        const user = this.$store.getters.getUserInfo;
        const url  = this.createdReviewData.photo[photoIdx];

        let response = await fetch('/public/review/rm_file', {
          method : 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body   : JSON.stringify({user, url})
        });
        response     = await response.json();

        if (!response.found) {
          throw new Error('Файл не найден')
        }

        this.previewPhotosURLArray.splice(photoIdx, 1);
        this.reviewPhotoFiles.splice(photoIdx, 1);
        this.$refs.reviewPhotosInput.value = null;
        this.createdReviewData.photo.splice(photoIdx, 1);
        this.modalErrors.maxReviewPhotosError = false;
      } catch (error) {
        console.error(`Ошибка при выполнении fetch: ${error.message}`);
      }
    },

    setReviewAuthorName() {
      this.createdReviewData.name = this.$store.getters.getUserInfo?.first_name || 'Анонiмний замовник'
    },

    async getReviews() {
      this.isLoadingReviews = true;
      let data              = await fetch(
          '/public/reviews',
          {method: 'POST', body: JSON.stringify({user: this.$store.getters.getUserInfo})}
      )
      data                  = await data.json();

      this.totalReviews           = data.total;
      this.loadMore               = data.last;
      this.moderatedReviewsNumber = data.moderated.length;
      this.isLoadingReviews       = false;
      return [...data.moderated, ...data.list] // это поле надо менять. временное решение для отобржения отзывов 
    },
    async handleReviewsListScroll() {
      const reviewsList = document.querySelector('.reviews-list');

      if (window.scrollY > reviewsList.offsetHeight - 150 && !this.isLoadingReviews) {
        await this.loadMoreReviews();
      }
    },

    async loadMoreReviews() {
      if (this.isLoadingReviews){
        return
      }
      if (!this.loadMore){
        return
      }
      this.isLoadingReviews = true;
      // let data = await fetch("/public/reviews", { method: "POST", body: JSON.stringify({ user: this.$store.getters.getUserInfo }) })

      let data = await fetch('/public/reviews', {method: 'POST', body: JSON.stringify({filter: {last: this.loadMore}})});
      data     = await data.json();

      this.totalReviews = this.totalReviews + data.total;
      this.loadMore               = data.last;
      this.moderatedReviewsNumber = data.moderated.length;
      this.isLoadingReviews = false;

      //block loading when empty list
      this.isLoadingReviews = !data.list;
      if (data.list.length) {
        this.reviews.push(...data.moderated, ...data.list);
      }
    },

    async postReview(event) {
      if (this.isReviewSending) {
        return
      }
      if (!this.createdReviewData.reviewRaiting) {
        this.modalErrors.notSelectedRaitingError = true;
        return
      }

      this.isReviewSending = true;

      const user = this.$store.getters.getUserInfo;
      if (user && user['photo_url']) {
        this.createdReviewData.reviewAuthorAvatar = user['photo_url'];

      } else {
        this.createdReviewData.reviewAuthorAvatar = undefined;
      }

      this.setReviewTime();
      this.setReviewAuthorName();

      const resp   = await fetch('/public/review', {method: 'POST', body: JSON.stringify({user: user, body: this.createdReviewData})})
      this.reviews = await this.getReviews();

      this.closeCreateReviewModal(event);
      this.openModalAfterReviewSent(resp.status);
    }
  },

  async mounted() {
    this.reviews                              = await this.getReviews();
    document.querySelector('title').innerHTML = 'Відгуки';

    window.addEventListener('scroll', this.handleReviewsListScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleReviewsListScroll);
  }
}
</script>

<style lang="scss" scoped>
.main-reviews-container {
  width: 100%;
  max-width: 805px;
  margin: 38px auto 0;
  padding: 0 15px;

  @media (max-width: 420px) {
    width: 100vw;
    margin-top: 0;
  }

  @media (min-width: 420px) and (max-width: 767px) {
    width: 375px;
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    max-width: 630px;
  }
}

.reviews-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 21px;
  padding: 27px 0 41px;
  border-radius: 13px;
  background: #F8F8F8;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10);

  @media (max-width: 767px) {
    padding: 6px 0 4px;
  }
}

.store-reviews-head {
  margin-bottom: 7px;
  color: #171717;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;

  @media (max-width: 340px) {
    font-size: 24px;
    margin-bottom: -16px;
  }

  @media (min-width: 341px) and (max-width: 767px) {
    font-size: 28px;
    margin-bottom: -16px;
  }
}

.total-mark-head {
  margin-top: 0;
  margin-bottom: 9px;
  color: #171717;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;

  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: -8px;
  }
}

.raiting-container {
  position: relative;
  display: flex;
  column-gap: 7px;
  margin-bottom: 8px;
  overflow: hidden;

  &.in-review-block {
    column-gap: 2px;
    margin-bottom: 0;
  }

  &.in-modal {
    column-gap: 24px;
    margin-bottom: 0;

    @media (max-width: 374px) {
      column-gap: 14px;
    }
  }

  &.filled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  @media (max-width: 767px) {
    margin-bottom: -11px;
  }
}

.raiting-star {
  flex-grow: 0;
  flex-shrink: 0;
  width: 42px;
  height: 38.25px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.in-review-block {
    width: 14px;
    height: 12.75px;

    @media (max-width: 767px) {
      width: 13px;
      height: 11px;
    }
  }

  &.in-modal {
    position: relative;
    width: 44px;
    height: 36px;
    fill: none;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 22px;
    height: 18px;
  }
}

.reviews-count-text {
  margin-bottom: 22px;
  color: #171717;
  font-size: 24px;
  line-height: 48px;

  @media (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.write-review-btn {
  padding: 12px 27px;
  border-radius: 13px;
  background: #171717;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  &:hover {
    background-color: #3b3b3b
  }

  @media (max-width: 767px) {
    display: none;
  }
}

//----------------------------------------------------------------
.reviews-header {
  margin-top: 0;
  margin-bottom: 18px;
  color: #212121;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 767px) {
    font-size: 23px;
    font-weight: 500;
  }
}

.reviews-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review-chain-section {
  margin-bottom: 9px;
}

.review-block {
  position: relative;
  padding: 5px 0 11px 0;
  border-radius: 13px;
  border: 1px solid rgba(0, 0, 0, 0.10);

  &.review-block--answer {
    margin-top: 14px;
    margin-left: 77px;

    @media (max-width: 767px) {
      margin-top: 7px;
      margin-left: 37px;
    }

    & .review-block__text {
      margin-right: 27px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 6px;
  }
}

.review-block__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  padding-bottom: 5px;
  border-bottom: 1px solid #D9D9D9;

  @media (max-width: 767px) {
    margin-bottom: 8px;
    padding-bottom: 6px;
  }
}

.review-block__header-part-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 767px) {
    gap: 5px;
  }

  &.left {
    margin-left: 12px;
  }

  &.right {
    gap: 3px;
    margin-right: 27px;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin-right: 12px;
    }
  }
}

.review-block__username-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.review-block__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E9E9E9;
  overflow: hidden;

  & span {
    line-height: 1;
  }

  & img {
    width: 100%;
    /* object-fit: cover; */
  }

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}


.review-block__status {
  margin-top: -2px;
  margin-bottom: 0;
  color: #747474;
  font-size: 8px;
  line-height: normal;

  &::after {
    content: url(/public/images/icons/already-buy-status-in-review.svg);
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }
}


.review-block__main-content {
  padding: 0 27px;

  @media (max-width: 767px) {
    padding: 0 12px;
  }
}


.review-block__tag-container {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 108px;
  margin-bottom: 15px;


  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
}


.review-block__photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin-right: 108px;

  @media (max-width: 767px) {
    gap: 3px;
  }
}

.review-block__photo {
  width: 76px;
  height: 60px;
  border-radius: 3px;
  object-fit: cover;

  @media (max-width: 767px) {
    width: 39px;
    height: 31px;
  }
}

.review-block__answers-counter {
  position: absolute;
  bottom: 11px;
  right: 27px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  color: #353535;
  font-size: 14px;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 2px;

  @media (max-width: 767px) {
    gap: 2px;
    right: 12px;
    font-size: 8px;
  }

  & svg {
    width: 10px;
    height: 10px;
  }
}

.load-more-reviews {
  width: 20px;
  margin: 18px auto 18px auto;
  background-color: transparent;
  border: none;
  color: #353535;
  font-size: 18px;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  cursor: pointer;

  & span {
    margin-right: 8px;
  }

  & svg {
    animation: load-more 1s linear infinite;
  }

  @keyframes load-more {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.add-review-section-only-mobile {
  @media (min-width: 768px) {
    display: none;
  }

  position: fixed;
  bottom: 52px;
  width: 100%;
  padding: 11px 0;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.07);

  & .write-review-btn {
    margin: 0 auto;
    padding: 7px 30px;
    display: block;
  }
}

//Modal CSS-----------------------------------------------------------------
.create-review-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  border-radius: 15px;
  background-color: #FFF;
  overflow: hidden;

  @media (min-width: 375px) {
    width: 375px;
  }

  @media (max-width: 767px) {
    top: auto;
    bottom: 52px;

    transform: translate(-50%, 0%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ECECEC;
    padding: 22px 15px 22px;
  }

  & .header b {
    color: #171717;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }
}

.close-modal-btn {
  cursor: pointer;

  &::after {
    width: 10px;
    height: 10px;
    background-color: red;
  }
}

.raiting-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 33px;
}

.raiting-container-text {
  display: flex;
  column-gap: 24px;

  @media (max-width: 374px) {
    column-gap: 14px;
  }

  & div {
    position: relative;
    text-wrap: nowrap;
    width: 44px;
    height: 0;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 11px;
    line-height: normal;

    & span {
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}

.section-line-devider {
  height: 1px;
  margin: 0 15px 0;
  background-color: rgba(0, 0, 0, 0.10);
}

.review-text-section {
  padding: 11px 15px 14px;
}

.modal-review-textarea {
  display: block;
  width: 100%;
  min-height: 115px;
  margin-bottom: 17px;
  padding: 12px 15px;
  border-radius: 7px;
  border: 1px solid #F5F5F5;
  background-color: #F5F5F5;
  font-size: 12px;

  &:hover {
    border: 1px solid #cdcdcd;
  }

  &::placeholder {
    color: #797979;
    font-size: 12px;
    line-height: 9px;
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.leave-comment-сall-to-action {
  margin-bottom: 14px;
  color: #797979;
  font-size: 12px;
  font-weight: 400;
  line-height: 9px;
}

.review-block__tag-container--in-modal {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  column-gap: 10px;
  row-gap: 7px;
}

.review-tag-checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  &:checked + .review-block__tag--in-modal {
    color: #fff;
    background-color: #171717;
    border-color: #171717;
  }
}

.review-block__tag--in-modal {
  padding: 4px 10px;
  margin-bottom: 0;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  background-color: #f3f3f3;

  &:hover {

    border-color: #868686;
  }

  &::before {
    display: none;
  }
}

.photo-section {
  padding: 13px 0 9px 0;
}

.modal-review__photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 9px;
  padding: 0 16.5px;
}

.modal-review__photo-wrapper {
  position: relative;
}

.modal-review__photo {
  width: 75px;
  height: 60px;
  border-radius: 3px;
  object-fit: cover;

}

.modal-review__photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  background-color: rgba(222, 222, 222, 0.70);;
}

.modal-review__photo-delete-btn {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 2;
  cursor: pointer;
}

.modal-review__photo-upload-btn-immitator {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 15px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 7px;
  border: 1px dashed #CDCDCD;
  background: #F5F5F5;
  color: #797979;
  font-size: 14px;
  line-height: 9px;
  cursor: pointer;

  &:hover {
    border: 1px dashed #cdcdcd;
    opacity: 0.75;
  }

  & input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
}

.modal-review__photo-upload-content-wrapper {
  display: flex;
  align-items: center;
  gap: 9px;
}

.modal-review__upload-photo-icon {
  width: 14px;
  height: 14px;
}

.send-btn-section {
  display: flex;
  justify-content: center;
  padding: 19px 0;

  @media (max-width: 767px) {
    padding: 12px 0;
  }
}

.write-review-btn--in-modal {
  padding: 11px 60px 11px;

  @media (max-width: 767px) {
    display: block;
  }
}

.help-text {
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translate(-50%, 0);
  color: red;
  background-color: #fff;
  margin-top: 10px;
  font-size: 11px;
  line-height: 1.2;
}

.help-text__in-raiting {
  top: 0;
  bottom: auto;
}

//--------after review sent modal

.review-after-send-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 281px;
  padding: 35px 49px;
  border-radius: 15px;
  background-color: #FFF;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 351px;
    padding: 48px 51px;
  }
}

.close-modal-btn__review-after-send-modal {
  position: absolute;
  top: 12px;
  right: 12px;

  @media (min-width: 768px) {
    top: 14px;
    right: 14px;
  }
}

.review-after-send-modal__content-wrapper {


  & b {
    display: block;
    margin-bottom: 8px;
    color: #353535;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;

    &.send-modal-error {
      color: red;
    }

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  & p {
    color: #171717;
    font-size: 12px;
    line-height: normal;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
}
</style>