<template>
  <div class="default-wrapper wrapper">
  <div class="not-found">
    <p>{{$static('auth.require', 'Необхідна авторизація')}}</p>
    <router-link :to="{name: 'home'}" class="default-btn" style="color:#ffffff;text-decoration: none">
      <span class="btn-text">{{$static('404.back', 'Назад на головну')}}</span>
    </router-link>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'AuthRequire',
    data() {
    },
    computed: {
      account() {
        return this.$store.getters.getAccount
      },
      userInfo() {
        return this.account?.telegram ? this.account?.telegram : this.baseUserInfo
      }
    },
    mounted() {
      console.log(this.userInfo)
      if (this.userInfo){
        this.$router.push({name:'CabinetHistory'})
      }
    },
    watch:{
      userInfo(val){
        if (val){
          this.$router.push({name:'CabinetHistory'})
        }
      }
    }
  }
</script>
